import React from 'react'

const Loader = () => {
  return (
    <div className="glitch-wrapper">
    <div className="glitch" data-glitch="CHAFROUD">CHAFROUD</div>
 </div>
  )
}

export default Loader

