import { useEffect, useRef } from "react";

const ShootingStar = () => {
  return (
    <section className="shootingStar_container">
      <span className="item-star"></span>
      {/* <span className="item-star"></span>

      <span className="item-star"></span> */}

      {/* <span className="item-star"></span> */}

      <span className="item-star"></span>

      {/* <span className="item-star"></span> */}

      {/* <span className="item-star"></span> */}

      {/* <span className="item-star"></span> */}

      <span className="item-star"></span>

      {/* <span className="item-star"></span> */}
    </section>
  );
};

export default ShootingStar;
