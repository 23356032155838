import Avatar from "../../../assets/images/astroleft.png";
const Main = () => {
  return (
    <section className="main_container" data-aos="fade-up">
      <div className="extra-header">
        <div className="info-header">
          <div className="title">
            <span className="hashtag">#</span>
            About-me
          </div>
          <div className="line"></div>
        </div>
        <span className="subheader">Who am i?</span>
      </div>

      <div className="main_container_info">
        <div
          className="leftSide"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <p>Hello, i’m Tarek!</p>
          <p>
            I am a proficient front-end developer based in Sousse, Tunisia, with
            a strong focus on React and Next.js. Crafting responsive web
            applications and building seamless user experiences are at the core
            of my expertise. I specialize in transforming creative ideas into
            functional websites, combining robust back-end functionalities with
            intuitive front-end designs. I am committed to staying updated with
            the latest technologies and frameworks, ensuring I deliver
            cutting-edge solutions.
          </p>
          <p>
            My commitment to excellence extends to collaborating with clients
            and contributing to their online presence. Whether it's coding,
            problem-solving, or learning new technologies, I am always eager to
            tackle challenges and bring innovation to web development.
          </p>
        </div>
        <div
          className="rightSide"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
        >
          <img
            className="astro-about"
            src={Avatar}
            alt="hero"
            style={{ marginTop: "20px" }}
          />
        </div>
      </div>
    </section>
  );
};

export default Main;
