import React from "react";
import AnonymFront from "../../../assets/images/astro.png";
import AnonymBack from "../../../assets/images/astroback.png";

import useWindowDimensions from "../../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
const Aboutme = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  return (
    <div className="aboutme_container" data-aos="fade-up">
      <div className="aboutme-header">
        <div className="title">
          <span className="hashtag">#</span>
          about-me
        </div>
        <div className="line"></div>
      </div>

      <div className="main_part">
        <div
          className="description"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <p>Hello, i’m Tarek!</p>
          <p>
            I'm a skilled frontend developer based in Sousse, Tunisia, with a
            strong focus on crafting responsive and user-friendly web
            applications. Over the past year, I've honed my expertise in
            transforming creative designs into seamless digital experiences.
          </p>
          <p>
            My passion lies in creating intuitive user interfaces and ensuring
            robust functionality on the frontend. I stay ahead of the curve by
            staying updated with the latest frameworks and technologies,
            ensuring that I deliver cutting-edge solutions to every project.
          </p>
          <div onClick={() => navigate("/about")} style={{ cursor: "pointer" }}>
            {"Read more ->"}
          </div>
        </div>
        <img
          className="astro"
          style={{
            marginTop: "40px",
            maxHeight: width < 800 ? "400px" : "600px",
          }}
          src={width < 800 ? AnonymBack : AnonymFront}
          alt="anonym"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
        />
      </div>
    </div>
  );
};

export default Aboutme;
